body {
  background-image: url(../image/bg.jpg);
}

.web-site{
  max-width: 1440px;
  max-height: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto auto;
}

.header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 65px;
  margin-top: 15px;

  &_logo{
    
    position: absolute;
    top: 11px;

    &>img{
      max-width: 90px;
    }
  }
}




.header_title{
  display: flex;
}

.header_title>span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 58px;
  font-weight: 700;
  color:#ffffff;
  text-align: start;
  text-transform: uppercase;
}

.content{
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}



.content_img{
  display: flex;
  flex-direction: column;
  &>img{
    max-width: 792px;
  }

  &>div{
    display: flex;
  }

  &>div>span{
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
    color:#ffffff;
    text-align: start;
    text-transform: uppercase;
    white-space: pre;
  }
}





.modail{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 294px;
  padding: 20px 50px;
  position: relative;
  top: 30px;
  z-index: 999;
  background-color:#ffffffbb;
  border-radius: 15px;
  color: #0057b8;
  &>span{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    line-height: 1.15;
    margin-bottom: 10px;
  }
  &:first-child{
    margin-bottom: 5px;
  }
  &_cost{
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 0px;
    &>span{
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 18px;
      line-height: 1.15;
      font-weight: 500;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}


.cost{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cost_button{
  width: 80px;
  height: 42px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  color: #ffffff;
  border:2px solid transparent;
  border-radius: 10px;
  background-color: #0057b8;  
  cursor: pointer;
  font-weight: 700;
}

.donate_button{
  width: 100%;
  height: 62px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #ffffff;
  border:2px solid transparent;
  border-radius: 10px;
  background-color: #0057b8;  
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 10px;

  &:hover{
    color: #0057b8;
    background-color: #ffffff; 
    border-color:#0057b8;
  }
}

.cost_button:hover{
  color: #0057b8;
  background-color: #ffffff;
}

.cost_button__act{
  color: #0057b8;
  background-color: #ffffff;
}

.modail_nav-case-currency{
  display: flex;
  flex-direction: column;

}

.display-currency{
  max-width: 292px;
  max-height: 42px;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 25px;
  line-height: 1.55;
  font-weight: 600;
  border: 2px solid #0057b8;
  border-radius: 10px;
  background-color: #ffffff;
  color: #0057b8;
  margin-bottom: 15px;
  outline: transparent;
  overflow: hidden;
}
.display-currency::placeholder{
  font-size: 20px;
  color: #0056b89d;
}

.display-currency__error{
  -webkit-box-shadow: 5px 3px 18px -1px #ff00009d; 
  box-shadow: 5px 3px 18px -1px #ff00009d;
}

.display-currency__error::placeholder{
  color: #ff00009d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; 
}


.button-next{
  width: 100%;
  max-width: 296px;
  min-height: 55px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.55;
  font-weight: 600;
  border: 2px solid #0057b8;
  border-radius: 10px;
  background-color: #0057b8;
  color: #ffffff;
  cursor: pointer;
}
.button-next:hover{
  background-color: #ffffff;
  color:  #0057b8;
}

.modail_logo{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.modail_logo__cent{
  justify-content: center;
}

.modail_logo>img{
  max-width: 70px;
}

.payModul>img{
  width:90px;
}

footer{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.title-footer{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 17px;
  line-height: 2;
  font-weight: 700;
  text-align: center;
  color: #0057b8;
}

.title-footer:first-child{
  font-size: 23px;
}

.logo_google{
  position: relative;
  top: 6px;
  height: 28px;
}
.logo_apple{
  position: relative;
  top:3.5px
}
.logo_visa{
  position: relative;
  top:2.5px
}

.am-ex_pay{
  max-height: 48px;
}



@media (max-width:1230px){
  body::-webkit-scrollbar{
    width: 0;
  }
  .content{
    display: flex;
    flex-direction: column;
    &_img>img{
      max-width: 692px;
    }
  }
  
  .modail{
    top: -230px;
  }

  footer{
    position: relative;
    top: -225px;
  }
}

@media (max-width:1150px){
  .header_logo{
    &>img{
      max-width: 90px;
    }
  }
  .content_img{
    &>img{
      margin: 0 auto;
      max-width: 592px;
    }
    &>div{
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 600px;
      
    }
    
  }

  .modail{
    top: -225px;
  }
}

@media (max-width:1040px){
  .web-site{
    padding: 0;
  }
  .content{
    flex-direction: column;
    position: relative;
  }
  

  .modail{
    position: absolute;
    top: 395px;
  }

  footer{
    position: relative;
    top: 220px;
  }
  
}

@media (max-width:900px) {
  .content_img{
    &>img{
      margin: 0 auto;
      // max-width: 592px;
    }
    &>div{
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 525px;
    }
  }
}

@media (max-width:800px){
  .header_logo{
    top:0px;

    &>img{
      max-width: 55px;
    }
  }

  .content_img{
    &>img{
      margin: 0 auto;
    }
    &>div{
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 450px;
      &>span{
        font-size: 40px;
      }
    }
  }

  .web-site{
    padding-top: 0px;
  }
  .modail{
    position: absolute;
    top: 285px;
  }

  footer{
    position: relative;
    top: 235px;
  }

  .content_img>img{
    max-width: 392px;
  }
  .header_title>span{
    font-size: 46px;
  }
}

@media (max-width:650px){
  .header{
    margin: 0;
  }


  .content_img{
    &>img{
      max-width: 392px;
    }
    &>div{
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 350px;
      &>span{
        font-size: 34px;
        line-height: 30px;
      }
    }
  }

  .header_logo{
    right: 3%;
  }

  .header_title>span{
    font-size: 35px;
  }

  .header_logo>img{
    width: 80px;
  }

  .title-footer{
    font-size: 12px;
    line-height: 1.15;
  }

  .title-footer:first-child{
    font-size: 15px;
  }

  footer{
    top: 285px;
  }
}

@media (max-width:450px){
  .header_logo{
    right: 3%;
  }
  .content_img>img{
    max-width: 352px;
  }
  footer{
    top:320px
  }

  .modail{
    padding: 20px 30px;
  }
}

@media (max-width:376px){
  .header_logo{
    right: 0%;
  }
  
  .content_img{
    &_img{
      max-width: 592px;
    }
    &>div{
      max-width: 300px
    }

    &>div>span{
      font-size: 30px;
      line-height: 30px;
    }
  }

  footer{
    top:240px
  }

  .title-footer{
    font-size: 12px;
    line-height: 1.15;
  }
  .title-footer:first-child{
    font-size: 15px;
  }

  .header{
    margin: 0 0px;
  }

  .header_title>span{
    font-size: 30px;
  }

  .modail{
    top: 220px;
  }

  .modail>span:first-child{
    font-size: 20px;
  }
}